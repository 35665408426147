<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import SecondaryButton from "@/components/SecondaryButton.vue";
import MainLogo from "@/components/MainLogo.vue";
import InfoColaborador from "./InfoColaborador.vue";

export default {
	components: {
		SecondaryButton,
		MainLogo,
		InfoColaborador,
	},
	data() {
		return {
			user: {},
			showForm: false,
			listed: false,
			users: [],
			fields: {
				name: { name: "Nombre" },
				work_position: { name: "Puesto" },
				territory: { name: "Territorio" },
				region: { name: "Región" },
				agency: { name: "Agencia" },
			},
			query: null,
		};
	},
	created() {
		this.showForm = false;
		this.loadFilter();
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",
			//READ_USERS
			readUsers: "searchUser/readUsers",

			//READ_ERRORS
			getUsersError: "searchUser/readError",
			deleteUserError: "deleteUser/readError",
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",
			//SET_USER_ID
			setDeleteId: "deleteUser/SET_ID",

			//SET_FILTERS_ID
			setUserFilter: "searchUser/SET_FILTER",

			//SET_ERRORS
			setUserError: "searchUser/SET_ERROR",
			setDeleteUserError: "deleteUser/SET_ERROR",
		}),
		...mapActions({
			//USERS
			getUsers: "searchUser/getUsers",
			deleteUser: "deleteUser/deleteUser",
		}),
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		loadSearch: _.debounce(function () {
			if (this.query.length > 0) {
				this.clearErrors();
				this.users = [];
				this.listed = false;
				this.setLoading(true);
				this.setUserFilter(this.query);
				this.getUsers()
					.then((response) => {
						if (response) {
							if (this.readUsers.length > 0) {
								this.users = this.readUsers;
							}
						}
					})
					.finally(() => {
						this.listed = true;
						this.setLoading(false);
					});
			} else {
				this.loadFilter();
			}
		}, 300),
		loadFilter() {
			this.users = [];
			this.listed = false;
			this.clearErrors();
			this.clearFilter();
		},
		remove(user) {
			this.listed = false;
			this.clearErrors();
			this.setLoading(true);
			this.$swal({
				title: "¿Desea eliminar este Colaborador?",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: "Aceptar",
			}).then((result) => {
				if (result.isConfirmed) {
					this.setDeleteId(user.id);
					this.deleteUser().then((response) => {
						if (response) {
							this.loadSearch();
							this.$swal({
								icon: "success",
								title: `El Colaborador ${
									!user.name || user.basic_info
										? `${user.first_name} ${user.last_name}`
										: user.name
								}  ha sido eliminado correctamente.`,
								showConfirmButton: false,
								timer: 3000,
							});
						}
					});
				}
			});
			this.setLoading(false);
			this.listed = true;
		},
		toggleForm(user) {
			this.user = user;
			this.showForm = !this.showForm;
		},
		clearFilter() {
			this.query = null;
		},
		clearErrors() {
			this.setUserError(null);
			this.setDeleteUserError(null);
		},
	},
};
</script>

<template>
	<div class="main">
		<info-colaborador
			v-on:toggleForm="toggleForm"
			v-on:remove="remove"
			v-if="showForm"
			:userInput="user"
		></info-colaborador>
		<form class="bg-gray px-6 m py-10 pb-10 shadow-sm mb-3">
			<div
				class="container mx-auto flex justify-center items-center flex-col md:flex-row"
			>
				<main-logo class="flex-none md:mr-6"></main-logo>
				<label
					for="query"
					class="w-full flex flex-col justify-between mt-3 md:mt-0 md:mr-6 text-lg flex-grow"
				>
					<input
						@keyup="loadSearch"
						required
						class="w-full px-4 py-2 text-lg bg-white rounded-md"
						type="text"
						name="query"
						placeholder="Buscar Colaborador"
						v-model="query"
					/>
				</label>

				<SecondaryButton
					@click.native="loadSearch"
					text="Buscar"
					class="mt-6 md:mt-0 flex-none"
				/>
			</div>
			<div class="w-full">
				<span
					v-if="getUsersError"
					class="block my-5 red text-center b"
					>{{ getUsersError }}</span
				>
				<span
					v-if="deleteUserError"
					class="block my-5 red text-center b"
					>{{ deleteUserError }}</span
				>
			</div>
		</form>
		<div v-if="!isLoading && users.length > 0 && listed">
			<table id="no-more-tables" class="block lg:table table-auto w-full">
				<thead class="block lg:table-header-group elektra-bg-red">
					<tr class="block lg:table-row border border-gray">
						<th
							class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
							v-for="(field, key) in fields"
							:key="key"
						>
							{{ field.name }}
						</th>
						<th
							class="block lg:table-cell b text-white border-r py-3 px-1"
						>
							Acciones
						</th>
					</tr>
				</thead>
				<tbody class="block md:flex flex-wrap lg:table-row-group">
					<tr
						class="block odd:bg-gray-light hover:bg-orange lg:table-row border-t border-b border-gray box-border cursor-pointer"
						:class="
							users.length > 1
								? 'w-full md:w-1/2 lg:w-full'
								: 'w-full'
						"
						v-for="(user, i) in users"
						:key="i"
					>
						<td
							@click="
								$router.push({
									name: 'DetalleColaborador',
									params: { id: user.id },
								})
							"
							class="block before:text-center before:justify-center first:border-l lg:table-cell text-center border-r border-gray-light py-3 px-1"
							:class="{ 'green b': key == 'id' }"
							v-for="(field, key) in fields"
							:key="key"
							v-show="key != 'password'"
						>
							<span
								v-capitalizeWords="
									user[key]
										? user[key]
										: `${user.first_name} ${user.last_name}`
								"
								v-if="key == 'name'"
							>
							</span>
							<span v-else v-capitalizeWords="user[key]"> </span>
						</td>
						<td
							class="flex justify-evenly px-1 items-center py-3 icons lg:table-cell"
						>
							<div
								class="w-full flex justify-evenly items-center"
							>
								<span
									class="title-hint"
									aria-label="Da clic aquí para editar este Colaborador"
								>
									<font-awesome-icon
										@click="toggleForm(user)"
										class="green hover:text-white text-3xl md:text-2xl cursor-pointer"
										icon="fa-solid fa-pen-to-square"
									/>
								</span>
								<span
									class="title-hint"
									aria-label="Da clic aquí para eliminar este Colaborador"
								>
									<font-awesome-icon
										@click="remove(user)"
										class="red hover:text-white text-3xl md:text-2xl cursor-pointer"
										icon="fa-solid fa-trash-can"
									/>
								</span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<span v-else-if="listed" class="block my-3 blue text-center b"
			>No se encontraron usuarios</span
		>
	</div>
</template>

<style lang="scss" scoped>
/*
	Label the data
	*/

@media (max-width: 1023px) {
	$fields: 1 "Nombre", 2 "Puesto", 3 "Territorio", 4 "Región", 5 "Agencia";

	@each $i, $field in $fields {
		#no-more-tables td:nth-child(#{$i}):before {
			content: $field;
		}
	}
}
</style>
